<template>
    <DataTable
        :value="roles"
        class="p-datatable-customers p-datatable-striped role-list"
        :scrollable="true"
        :paginator="true"
        :rows="pageSize"
        scroll-height="calc(100vh - 350px)"
        :rowsPerPageOptions="rowsPerPageOptions"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate=""
        :totalRecords="totalRecords"
        @row-dblclick="rowClicked"
        dataKey="id"
        selectionMode="single"
        :selection.sync="selectedRoles"
        :filters="filters"
        stripedRows
        resizable-columns
    >
        <template #header>
            <div class="table-header p-flex-wrap p-d-flex">
                <div class="p-col-8 p-p-0 p-d-flex p-ai-center">
                    <h5 class="p-d-inline p-mb-0 p-pt-0">Роли</h5>
                </div>
                <div class="p-col-5 p-p-0 p-d-flex p-ai-center p-mt-2"></div>
                <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-jc-end table-global-search">
                    <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                        <span class="p-float-label">
                            <InputText type="text" v-model="filters['global']" placeholder="Поиск" />
                        </span>
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                    <div v-if='canEdit' class="p-p-0 p-new-btn">
                        <Button @click="$router.push('/control/roles/new')" class="p-button"> Добавить </Button>
                    </div>
                </div>
            </div>
        </template>
        <template #empty> Ролей не найдено.</template>
        <template #loading> Загрузка списка ролей. Пожалуйста, подождите.</template>
        <!--        <Column header="ID" headerStyle="width: 3rem">-->
        <!--            <template #body="slotProps">-->
        <!--                <div class="p-text-nowrap p-text-truncate">{{ slotProps.data.id }}</div>-->
        <!--            </template>-->
        <!--        </Column>-->
        <Column header="Наименование" field="attributes.name" header-class="role-header-name table-column" body-class="role-body-name" sortable>
            <template v-if="showFilters" #filter>
                <InputText type="text" v-model="filters['attributes.name']" placeholder="Поиск" />
            </template>
        </Column>
        <Column header="Описание" field="attributes.description" header-class="role-header-desc table-column" body-class="role-body-desc" body-style="max-height: 57px;" sortable>
            <template #body="{ data }">
                <div class="desc-cell" :title="data.attributes.description">
                    {{ data.attributes.description }}
                </div>
            </template>
            <template v-if="showFilters" #filter>
                <InputText type="text" v-model="filters['attributes.description']" placeholder="Поиск" />
            </template>
        </Column>
        <Column header="" header-class="p-text-center" body-style="position: relative; width: 52px; text-align: center;" header-style="width: 52px;">
            <template v-if='accessibleItems(listMenuItems).length' #body="slotProps">
                <div class="p-panel-header-icon p-link" @click.stop="toggleRowMenu($event, slotProps)">
                    <span class="pi pi-ellipsis-h"></span>
                </div>
                <Menu class="redLastListElement" :ref="`listMenu${slotProps.data.id}`" :model="accessibleItems(listMenuItems)" :popup="true" :baseZIndex="10" />
            </template>

            <template #header>
                <Button icon="pi pi-filter" :class="filterClasses" class="p-button-rounded p-button-outlined filter-btn" @click="showFilters = !showFilters" />
            </template>
        </Column>
        <template #paginatorLeft>
            <div class="p-col-6"></div>
        </template>
    </DataTable>
</template>

<script>
import { getAllRoles, deleteRole } from '@/api/role';
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { USER_PERMISSIONS_MAP, DEFAULT_PAGE_SIZE } from '@/constants/common';

const {
    rbacWrite
} = USER_PERMISSIONS_MAP

export default {
    name: 'List',
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    emits: ['loadingChange'],
    mixins: [requestToastHandler],
    data() {
        this.pageSize = DEFAULT_PAGE_SIZE;

        return {
            selectedRoles: null,
            showFilters: false,
            filters: {
                attributes: {},
            },
            filter: {},
            editRoleId: null,
            roles: [],
            allUsers: [],
            currentPage: 1,
            totalRecords: null,
            listMenuItems: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editRole();
                    },
                    permissionAccess: [rbacWrite]
                },
                {
                    label: 'Удалить',
                    icon: 'pi pi-trash',
                    command: () => {
                        // this.showDeletePopUp(this.currentPeriodId);
                        // this.removeRole();
                        this.$root.$emit('showAcceptDeleteDialog', {
                            acceptAction: this.removeRole
                        })
                    },
                    class: 'remove-row',
                    permissionAccess: [rbacWrite]
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'userPermissionsObject',
            'accessibleItems'
        ]),
        filterClasses() {
            return this.showFilters ? 'mainBackground' : '';
        },
        canEdit() {
            return [rbacWrite].some(p => this.userPermissionsObject[p])
        }
    },
    methods: {
        rowClicked({ data }) {
            this.editRoleId = data.id;
            this.editRole();
        },
        toggleRowMenu(event, slotProps) {
            const {
                data: { id },
            } = slotProps;
            // Close previous popup
            if (id !== this.editRoleId) {
                this.$refs[`listMenu${this.editRoleId}`] && this.$refs[`listMenu${this.editRoleId}`].hide(event);
            }
            this.editRoleId = id;
            // Open new popup
            this.$refs[`listMenu${id}`].toggle(event);
        },
        paginationChange(event) {
            this.currentPage = event.page + 1;
            this.pageSize = event.rows;
            this.getRoles();
        },
        editRole() {
            if (!this.canEdit) return
            this.$router.push(`/control/roles/edit/${this.editRoleId}`);
        },
        async getRoles() {
            try {
                this.$emit('loadingChange', true);
                const result = await getAllRoles(this.currentPage, this.pageSize);
                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }
                this.roles = result.roles;
                this.totalRecords = result.meta.pagination.total;
            } catch (error) {
                this.$requestError(error.message);
            }
            this.$emit('loadingChange', false);
        },

        async removeRole() {
            try {
                this.$emit('loadingChange', true);
                const result = await deleteRole(this.editRoleId);
                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }
                this.roles = this.roles.filter((roles) => roles.id !== this.editRoleId);
                this.$emit('loadingChange', false);
                // this.$toast.add({
                //     severity: 'success',
                //     summary: 'Роль успешно удалена',
                //     life: 2500,
                // });
            } catch (error) {
                this.$requestError(error.message);
            }
        },

        addRoleCount(roleId) {
            const roleCount = this.allUsers.reduce((acc) => {
                // user.role.id == roleId ? acc++ : null;
                roleId;
                acc++;
                return acc;
            }, 0);
            return roleCount;
        },
    },

    async created() {
        await this.getRoles();
        this.$emit('loadingChange', false)
    },
};
</script>

<style lang='scss' scoped>
.p-datatable {
    &-customers {
        ::v-deep {
            .p-panel-header-icon {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                &:hover {
                    background: rgba(135, 148, 163, 0.25);
                }
            }
            .table-column {
                padding-right: 3.411vw;
            }

            .role-header {
                &-name {
                    width: 19.53125vw;
                }
                //&-desc {
                //    width: 34.661vw;
                //}
            }

            .role-body {
                &-name {
                    width: 19.53125vw;
                }
                &-desc {
                    //width: 34.661vw;
                    .desc-cell {
                        display: -webkit-box;
                        overflow: hidden;
                        max-width: 100%;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            .p-datatable {
            }
        }
    }
}
</style>
